export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/articles": [4],
		"/article/[id]": [~3],
		"/index.php/pubs/article/download/[old_id]": [5],
		"/index.php/pubs/article/download/[old_id]/[file_id]": [6],
		"/index.php/pubs/article/viewFile/[old_id]": [9],
		"/index.php/pubs/article/viewFile/[old_id]/[file_id]": [10],
		"/index.php/pubs/article/view/[old_id]": [7],
		"/index.php/pubs/article/view/[old_id]/[file_id]": [8],
		"/index.php/pubs/issue/view/[old_issue_id]": [11],
		"/issues": [~13],
		"/issue/[issue_id]": [~12],
		"/pubs/article/download/[old_id]": [14],
		"/pubs/article/download/[old_id]/[file_id]": [15],
		"/pubs/article/viewFile/[old_id]": [18],
		"/pubs/article/viewFile/[old_id]/[file_id]": [19],
		"/pubs/article/view/[old_id]": [16],
		"/pubs/article/view/[old_id]/[file_id]": [17],
		"/pubs/issue/view/[old_issue_id]": [20],
		"/search": [~21]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';